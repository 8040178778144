.form-li-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 30px !important;
}

.bottom-nav-driver {
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  width: 35vw;
  background-color: white;
  z-index: 1;
}

.right-align {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 25px;
}

.top-bar {
  padding: 20px;
  /* width: 100%; */
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between !important;
}
