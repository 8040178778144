body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.head {
  font-family: "Lora", serif !important;
}

a {
  color: rgb(0, 75, 145);
  cursor: pointer;
  text-decoration: none;
}
